import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult, SweetAlertOptions } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
  })
export class AlertService {

  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  constructor() {
  }

  toastInfo(message: string) {
    this.Toast.fire({ icon: 'info', title: message } as SweetAlertOptions);
  }

  toastError(message: string) {
    this.Toast.fire({ icon: 'error', title: message } as SweetAlertOptions);
  }

  toastSuccess(message: string) {
    this.Toast.fire({ icon: 'success', title: message } as SweetAlertOptions);
  }

  toastProgress() {
    const p = {} as SweetAlertOptions;
    p.icon = 'info';
    p.title = 'Please wait...';
    p.position = 'bottom-right';
    this.Toast.fire(p);
  }

  prompt(title: string, text?: string, htmlFooter?: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      html: text,
      input: 'text',
      icon: 'question',
      showCancelButton: true,
      footer: htmlFooter
    } as SweetAlertOptions);
  }

  alert(title: string, text?: string, htmlFooter?: string, buttonText: string = 'Ok') {
    const options: SweetAlertOptions = {
      title,
      html: text,
      confirmButtonText: buttonText,
      showConfirmButton: true,
      footer: htmlFooter
    };

    Swal.fire(options);
  }

  confirm(title: string, text?: string, yesText?: string, noText?: string): Promise<SweetAlertResult> {
    if (!yesText) {
      yesText = 'Yes';
    }
    if (!noText) {
      noText = 'No';
    }
    return Swal.fire({
      title,
      html: text,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: yesText,
      cancelButtonText: noText

    } as SweetAlertOptions);
  }
}
