import { Injectable, inject } from '@angular/core';
import { GeoService } from '../../api/services';

@Injectable({
    providedIn: 'root'
  })
export class LocationService {
  private geoService = inject(GeoService);


  locateIp(callback: any) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        callback(
          this.geoService.reverse({ lat: position.coords.latitude, lng: position.coords.longitude }));
      });
    } else {
      callback(this.geoService.locateIp());
    }
  }
}
